exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-business-js": () => import("./../../../src/pages/business.js" /* webpackChunkName: "component---src-pages-business-js" */),
  "component---src-pages-feature-activity-js": () => import("./../../../src/pages/feature/activity.js" /* webpackChunkName: "component---src-pages-feature-activity-js" */),
  "component---src-pages-feature-calls-js": () => import("./../../../src/pages/feature/calls.js" /* webpackChunkName: "component---src-pages-feature-calls-js" */),
  "component---src-pages-feature-emoji-js": () => import("./../../../src/pages/feature/emoji.js" /* webpackChunkName: "component---src-pages-feature-emoji-js" */),
  "component---src-pages-feature-engagement-js": () => import("./../../../src/pages/feature/engagement.js" /* webpackChunkName: "component---src-pages-feature-engagement-js" */),
  "component---src-pages-feature-interactions-js": () => import("./../../../src/pages/feature/interactions.js" /* webpackChunkName: "component---src-pages-feature-interactions-js" */),
  "component---src-pages-feature-links-js": () => import("./../../../src/pages/feature/links.js" /* webpackChunkName: "component---src-pages-feature-links-js" */),
  "component---src-pages-feature-ratios-js": () => import("./../../../src/pages/feature/ratios.js" /* webpackChunkName: "component---src-pages-feature-ratios-js" */),
  "component---src-pages-feature-sentiment-js": () => import("./../../../src/pages/feature/sentiment.js" /* webpackChunkName: "component---src-pages-feature-sentiment-js" */),
  "component---src-pages-feature-streaks-js": () => import("./../../../src/pages/feature/streaks.js" /* webpackChunkName: "component---src-pages-feature-streaks-js" */),
  "component---src-pages-feature-word-cloud-js": () => import("./../../../src/pages/feature/word-cloud.js" /* webpackChunkName: "component---src-pages-feature-word-cloud-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-instagram-js": () => import("./../../../src/pages/instagram.js" /* webpackChunkName: "component---src-pages-instagram-js" */),
  "component---src-pages-messenger-js": () => import("./../../../src/pages/messenger.js" /* webpackChunkName: "component---src-pages-messenger-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-terms-of-service-js": () => import("./../../../src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */),
  "component---src-pages-visas-js": () => import("./../../../src/pages/visas.js" /* webpackChunkName: "component---src-pages-visas-js" */),
  "component---src-pages-whatsapp-js": () => import("./../../../src/pages/whatsapp.js" /* webpackChunkName: "component---src-pages-whatsapp-js" */),
  "component---src-templates-blog-template-js": () => import("./../../../src/templates/blogTemplate.js" /* webpackChunkName: "component---src-templates-blog-template-js" */)
}

