/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import amplitude from 'amplitude-js'
import { isBrowser } from './src/utils/index'

export const onClientEntry = () => {
  const apiKey =  process.env.NODE_ENV === `production` ? 'e9b34a2a6c7facebd730a7486ba7d852' : '183e96dcc8c16863f8400249b3eff60a'

  amplitude.init(apiKey, null, {
    saveEvents: true,
    includeUtm: true,
    includeReferrer: true
  })
  const ac = amplitude.getInstance()
  if(isBrowser()) {
    ac.logEvent('LANDING_PAGE_VIEW', { href: window.location.href })
  } else {
    ac.logEvent('LANDING_PAGE_VIEW')
  }
};
